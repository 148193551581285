<template>
  <!-- 付款申请页 -->
  <div class="table-responsive mt-5">
    <div class="text-center">
      <h3>申请付款</h3>
    </div>
    <table class="table table-bordered table-sm dark">
      <tbody>
        <tr>
          <td colspan="8" style="background-color: rgba(152, 150, 245, 0.05)">
            基本信息
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>日期</small></td>
          <td>
            <el-date-picker
              v-model="formdata.sq_date"
              type="date"
              placeholder=""
              size="small"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            ></el-date-picker>
          </td>
          <td class="text-center align-middle"><small>部门</small></td>
          <td>
            <department-select
              @select-change="departmentselect"
              :placeholder="departmentplace"
            ></department-select>
          </td>
          <td class="text-center align-middle"><small>付款金额</small></td>
          <td>
            <el-input
              v-model="formdata.sq_amount"
              placeholder=""
              size="small"
            />
          </td>
          <td class="text-center align-middle"><small>付款账户</small></td>
          <td>
            <!--banksclass 1-表示收付款账户  2表示往来款账户 -->
            <sfbanks-select
              @select-change="sfbanksselect"
              :placeholder="sfbankplace"
              banksclass="1"
            ></sfbanks-select>
          </td>
        </tr>

        <tr>
          <td><small>所属合同</small></td>
          <td>
            <el-select
              v-model="contract_info"
              placeholder=""
              size="small"
              @change="contract_select"
            >
              <el-option
                v-for="item in contract_list"
                :key="item.id"
                :label="item.contract_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center align-middle"><small>收款户名</small></td>
          <td>
            <el-select
              v-model="sk_bank_name"
              placeholder="Select"
              size="small"
              @change="banks_select"
            >
              <el-option
                v-for="item in sk_bank_list"
                :key="item.id"
                :label="item.account_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center align-middle"><small>收款账号</small></td>
          <td>
            <samp>{{ formdata.supplier_banks_no }}</samp>
          </td>
          <td class="text-center align-middle">
            <small>收款开户行</small>
          </td>
          <td>
            <samp>{{ formdata.supplier_banks_name }}</samp>
          </td>
        </tr>

        <tr>
          <td>付款说明</td>
          <td colspan="7">
            <el-input
              v-model="formdata.fk_text"
              :rows="4"
              type="textarea"
              placeholder=""
            />
          </td>
        </tr>
      </tbody>
    </table>
    <!-- 确认新增付款申弹框 -->
    <el-popconfirm
      title="您确定要新增付款申吗？"
      confirm-button-text="确定"
      cancel-button-text="取消"
      confirm-button-type="primary"
      @confirm="addcontract"
    >
      <template #reference>
        <el-button
          :disabled="issaveok"
          type="primary"
          size="mini"
          style="margin-left: 48%"
          >保存新增</el-button
        >
      </template>
    </el-popconfirm>

    <div>
      <el-button :disabled="!issaveok" size="mini" @click="dialogVisible = true"
        >批量上传</el-button
      >

      <el-dialog v-model="dialogVisible" width="50%" @closed="getFileList">
        <div class="text-center">
          <file-upload
            :fileclass="fileclass"
            :prdclassid="prdclassid"
            urlpath="admin/AccessoryFile"
          ></file-upload>
        </div>
      </el-dialog>
      <el-table :data="tableData.data">
        <el-table-column prop="file_name" label="文件名称" width="300" />
        <el-table-column prop="create_time" label="上传时间" width="150" />
        <el-table-column label="操作" width="150" align="center">
          <template #default="scope">
            <el-button-group>
              <el-button
                size="small"
                plain
                @click="openPdfFile(scope.row.url_path)"
                >查看</el-button
              >
              <el-button size="small" plain type="danger">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

  <!-- 查看PDF文件 -->
  <el-dialog
    v-model="pdfurlVisible"
    width="100%"
    fullscreen
    @closed="pdfurl = ''"
  >
    <div class="text-center" style="height: 2000px">
      <iframe :src="pdfurl" width="100%" height="100%"></iframe>
    </div>
  </el-dialog>
  <!-- 显示供应商合同/付款/发票情况 -->
  <el-drawer v-model="drawer" title="合同/付款/发票一览表" size="50%">
    <get-supplier-fkinfo
      :supplier_info_id="formdata.supplier_info_id"
      :project_id="formdata.project_id"
    ></get-supplier-fkinfo>
  </el-drawer>
</template>

<script>
import axios from "axios";
import { ElMessageBox, ElMessage } from "element-plus";
import { h } from "vue";
export default {
  props: ["perprops", "supplierid"], //父组件传过来的信息 格式为对象{}
  data() {
    return {
      departmentplace: "", //部门分类默认值 修改前的值
      sfbankplace: "", //付款银行默认值 修改前的值

      pdfurl: "", //PDF文件urlf地址
      pdfurlVisible: false, //查看显示PDF文件
      fileclass: "2", //分类ID 0-项目图片  1-付款合同  2-付款申请   3-发票   4-银行回单
      prdclassid: "", //新增记录ID
      dialogVisible: false, //是否打开上传模态框
      issaveok: false, //如果增加成功，则新增按钮失效

      contract_info: "", //对应合同信息
      sk_bank_name: "", //收款账户名称

      contract_list: [], //合同列表
      sk_bank_list: [], //收款银行列表

      zjjhfksums: 0, //资金计划表记录,对应付款申请表中已申请付款和已付款成功的合计

      formdata: {
        id_no: "xxx-xxx-xx-x", //凭证编号
        sq_date: "", //日期
        department_id: "", //部门ID
        sq_amount: "", //付款金额
        sf_banks_id: "", //付款账户ID
        category_id: "", //成本ID
        supplier_info_id: this.perprops.supplier_id, //供应商ID
        fcontract_info_id: "", //合同ID
        supplier_banks_id: "", //收款银行ID
        proposer_id: JSON.parse(window.sessionStorage.getItem("user")).id, //申请人ID
        approver_id: "", //审批人ID
        cfo_id: "", //财务人ID/审核人ID
        registrant_id: "", //付款登记人ID
        resource_is: "", //资料齐否状态
        order_status: 1, //记录状态
        project_id: window.sessionStorage.getItem("project_ids"), //项目ID
        fk_text: "", //付款说明
        supplier_banks_no: "", //收款银行账号
        supplier_banks_name: "", //收款银行名称
        zjjhlist_id: this.perprops.zjjhlist_id, //资金计划列表ID
      },

      //附件列表数据
      tableData: {
        data: [],
      },
      drawer: false, //是否打开抽屉控件
    };
  },
  watch: {
    supplierid() {
      this.getsuppliercontbank(this.perprops.supplier_id);
      this.getzjjhfksum(this.perprops.zjjhlist_id);
    },
  },
  created() {
    this.getsuppliercontbank(this.perprops.supplier_id);
    this.getzjjhfksum(this.perprops.zjjhlist_id);
  },
  methods: {
    //初始化参数
    init_props() {
      //弹出供应商合同/付款/发票信息控件
      this.drawer = true;
    },
    //单击部门控件事件
    departmentselect(val) {
      console.log("部门控件子组件传来的数据", val);
      this.formdata.department_id = val.id;
    },
    //单击付款账户控件事件
    sfbanksselect(val) {
      console.log("单击付款账户子组件控件事件", val);
      this.formdata.sf_banks_id = val.id;
    },

    //选择收款账户，触发事件
    banks_select(val) {
      this.sk_bank_list.forEach((dataval) => {
        if (val == dataval["id"]) {
          this.formdata.supplier_banks_no = dataval["account_no"];
          this.formdata.supplier_banks_name = dataval["banks"];
        }
      });
      this.formdata.supplier_banks_id = val;
    },

    //选择‘所属合同’，触发事件
    contract_select(val) {
      console.log("所属合同控件传来的数据", val);
      this.formdata.fcontract_info_id = val;
    },
    //新增付款申请记录
    addcontract() {
      //判断申请金额合计是否超过审批金额（审批金额-已申请和已支付金额-本次申请的金额）
      let chavul =
        parseFloat(this.perprops.sp_amount) - parseFloat(this.zjjhfksums) - parseFloat(this.formdata.sq_amount);
      //如果超出，不能申请结束程序，重新输入
     if (chavul < 0) {
        ElMessage({
          message: h("p", null, [
            h("span", null, "不能申请，"),
            h("i", { style: "color: teal" }, "累计超出审批金额"),
          ]),
        });
        return;
      }
      console.log("新增付款申请记录", this.formdata);
      axios
        .post("admin/FkRequest", this.formdata, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then((response) => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });
          this.issaveok = true; //禁用新增按钮，打开上传按钮
          this.prdclassid = response.data.data; //获取新增ID
          console.log("新增ID", this.prdclassid);
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
    //获取对应记录的附件
    getFileList() {
      axios
        .get("admin/AccessoryFile", {
          params: {
            fileclass_id: this.fileclass,
            record_id: this.prdclassid,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******", response.data.data, "******");
          this.tableData.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //查看PDf
    openPdfFile(url) {
      this.pdfurlVisible = true;
      this.pdfurl = url;
    },
    //获取供应商
    getsuppliercontbank(supplierid) {
      axios
        .get("admin/SupplierInfo/indexone", {
          params: {
            id: supplierid,
            project_id: window.sessionStorage.getItem("project_ids"),
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(
            "9******9",
            response.data.data[0].FcontractInfos,
            "******"
          );
          this.contract_list = response.data.data[0].FcontractInfos;
          this.sk_bank_list = response.data.data[0].SupplierBanks;
          this.formdata.category_id = this.perprops.category_id;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //计算资金计划表记录,对应付款申请表中已申请付款和已付款成功的合计
    getzjjhfksum(zjjhlistid) {
      axios
        .get("admin/FkRequest/getsumzjjh", {
          params: {
            zjjhlist_id: zjjhlistid,
            project_id: window.sessionStorage.getItem("project_ids"),
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
         
          this.zjjhfksums = response.data.data;
             console.log("总申请合计：",this.zjjhfksums)
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
  },
};
</script>


<style>
</style>