<template>
  <div class="mt-5">
    <span>请选择资金计划所属期：</span>
    <el-select
      v-model="jhdateval.jhdate"
      class="m-2"
      placeholder="Select"
      size="small"
      @change="selectjhdate"
    >
      <el-option
        v-for="item in zj_date"
        :key="item.id"
        :label="item.jhdate"
        :value="item.jhdate"
      />
    </el-select>
    <el-button
      class="float-right"
      @click="buttjsh()"
      size="small"
      v-if="this.jhdateval.Steps === 0"
      >提交申请</el-button
    >
    <el-button
      class="float-right"
      @click="buttjsh()"
      size="small"
      v-if="this.jhdateval.Steps === 2"
      >请款结束</el-button
    >
  </div>
  <el-divider><i class="el-icon-star-off"></i> </el-divider>
  <div class="text-center mt-5">
    <h2>{{ jhdateval.jhdate }}资金计划表(申请)</h2>
    <el-steps :active="jhdateval.Steps" align-center finish-status="success">
      <el-step title="申请" />
      <el-step title="待审核" />
      <el-step title="请款中" />
      <el-step title="完结" />
    </el-steps>
  </div>
  <!-- 资金计划申请 -->
  <el-table
    style="width: 100%"
    :data="options"
    row-key="id"
    border
    height="100%"
    :default-expand-all="true"
    :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
    :row-style="TableRowStyle"
    :header-cell-style="{ background: '#000080', color: '#ffff' }"
  >
    <el-table-column prop="name" label="名称" min-width="100" align="center">
      <el-table-column
        prop="name"
        label="合计"
        min-width="100"
        align="center"
      />
    </el-table-column>

    <el-table-column
      prop="contarctamout"
      label="合同额"
      width="160"
      align="center"
    >
      <el-table-column
        prop="contarctamout"
        :label="toThousands('', '', totalamount.contarctamout)"
        width="160"
        align="right"
        :formatter="toThousands"
    /></el-table-column>
    <el-table-column
      prop="requestamout"
      label="发生额"
      width="160"
      align="center"
      :formatter="toThousands"
      ><el-table-column
        prop="requestamout"
        :label="toThousands('', '', totalamount.requestamout)"
        width="160"
        align="right"
        :formatter="toThousands"
    /></el-table-column>
    <el-table-column
      prop="difference"
      label="未发生额"
      width="160"
      align="center"
      :formatter="toThousands"
      ><el-table-column
        prop="difference"
        :label="toThousands('', '', totalamount.difference)"
        width="160"
        align="right"
        :formatter="toThousands"
    /></el-table-column>
    <el-table-column
      prop="jh_amount"
      label="计划金额"
      width="160"
      align="center"
    >
      <el-table-column
        prop="jh_amount"
        :label="toThousands('', '', totalamount.jh_amount)"
        width="160"
        align="right"
      >
        <template #default="scope">
          <input
            type="text"
            v-model="scope.row.jh_amount"
            v-show="scope.row.isedit"
          />
          <span v-show="!scope.row.isedit">{{
            toThousands(scope.row, scope.row, scope.row.jh_amount)
          }}</span>
        </template>
      </el-table-column></el-table-column
    >

    <el-table-column
      prop="sp_amount"
      label="审核金额"
      width="160"
      :formatter="toThousands"
      align="center"
    >
      <el-table-column
        prop="sp_amount"
        :label="toThousands('', '', totalamount.sp_amount)"
        width="160"
        :formatter="toThousands"
        align="right"
      />
    </el-table-column>
    <el-table-column label="操作" width="280" align="center">
      <template #default="scope">
        <el-button-group v-if="this.jhdateval.Steps === 0">
          <el-button
            @click="edit(scope.row)"
            size="small"
            v-if="scope.row.supplier_id"
            >编辑</el-button
          >
          <el-button
            @click="save(scope.row)"
            size="small"
            v-if="scope.row.supplier_id"
            >保存</el-button
          >
          <el-button
            @click="getsupplierfkinfo(scope.row)"
            size="small"
            v-if="scope.row.supplier_id"
            >查看</el-button
          >
          <el-button
            @click="uploadfile(scope.row)"
            size="small"
            v-if="scope.row.supplier_id"
            >上传附件</el-button
          >
        </el-button-group>
        <el-button
          @click="butfksq(scope.row)"
          size="small"
          :disabled="scope.row.sp_amount == 0"
          v-if="this.jhdateval.Steps === 2 && scope.row.supplier_id"
          >申请付款</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <!-- 创建付款申请单 -->
  <el-dialog v-model="fkdidVisible" width="100%" fullscreen>
    <zjjh-fksq
      :perprops="perprops"
      :supplierid="perprops.supplier_id"
    ></zjjh-fksq>
  </el-dialog>
  <!-- 显示供应商合同/付款/发票情况 -->
  <el-drawer v-model="drawer" title="合同/付款/发票一览表" size="50%">
    <get-supplier-fkinfo
      :supplier_info_id="supplier_info_id"
      :project_id="project_id"
    ></get-supplier-fkinfo>
  </el-drawer>
  <!-- 上传附件对话框 -->
  <el-dialog v-model="dialogVisible" width="50%">
    <div class="text-center">
      <file-upload
        :fileclass="fileclass"
        :prdclassid="prdclassid"
        urlpath="admin/AccessoryFile"
        filedir="zjjhfj"
      ></file-upload>
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";
import { ElMessageBox, ElMessage } from "element-plus";
import zjjhfksq from "../../../components/zjjh/createfksq.vue";
import { h } from "vue";
export default {
  data() {
    return {
      //分类ID     0-项目图片  1-付款合同  2-付款申请   3-发票
      //           4-银行回单  5-收款合同  6-收款凭证  7-资金计划附件
      fileclass: "7",
      prdclassid: "", ////资金计划表 记录ID
      dialogVisible: false, //是否打开上传模态框

      //供应商ID
      supplier_info_id: "",
      project_id: window.sessionStorage.getItem("project_ids"),
      drawer: false,
      //
      perprops: {
        category_id: "", //成本分类
        supplier_id: "", //供应商ID
        sp_amount: "", //审批金额
        name: "", //供应商名称
        project_id: "", //项目ID
        zjjhlist_id: "", //资金计划表记录ID
      },
      //创建付款申请单模态框
      fkdidVisible: false,
      //资金计划表所属期 信息
      jhdateval: {
        Steps: -1,
        id: 0,
        jhdate: "",
      },
      zj_date: [],

      options: [],

      // 计算合计金额  totalamount.contarctamout difference jh_amount requestamout sp_amount
      totalamount: {
        contarctamout: 0, // 合同额
        requestamout: 0, //发生额
        difference: 0, // 未发生额
        jh_amount: 0, // 计划额
        sp_amount: 0, //审批额
      },
    };
  },
  created() {
    this.zjjhdate();
  },
  methods: {
    //上传附件按钮
    uploadfile(row) {
      //判断是否存在资金计划记录，如果存在则可以上传附件，如果不存在就不能上传附件
      this.axios
        .get("admin/Zjjhlist/read", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            jhmonth: this.jhdateval.jhdate,
            supplier_id: row.supplier_id,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          //如果记录存在 则可以上传附件
          if (response.data.id) {
            this.prdclassid = response.data.id;
            this.dialogVisible = true;
          } else {
            ElMessage({
              message: h("p", null, [
                h("span", null, "不能上传文件，"),
                h("i", { style: "color: teal" }, "请先设置金额再上传附件"),
              ]),
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //<!-- 显示供应商合同/付款/发票情况 -->
    getsupplierfkinfo(row) {
      this.supplier_info_id = row;
      this.drawer = true;
    },
    //付款申请
    butfksq(row) {
      this.perprops.category_id = row.category_id_t;
      this.perprops.supplier_id = row.supplier_id;
      this.perprops.sp_amount = row.sp_amount;
      this.perprops.name = row.name;
      this.perprops.project_id = row.project_id;
      this.perprops.zjjhlist_id = row.zjjhlist_id;
      console.log(row);
      this.fkdidVisible = true;
    },
    //点击提交申请按钮事件
    buttjsh() {
      let updatesetps = {};
      if (this.jhdateval.Steps === 0) {
        //提交申请
        updatesetps = {
          id: this.jhdateval.id,
          Steps: 1,
        };
      }
      if (this.jhdateval.Steps === 2) {
        //提交请款完成
        updatesetps = {
          id: this.jhdateval.id,
          Steps: 4,
        };
      }

      axios
        .post("admin/zjjhdate/update", updatesetps, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });
          if (this.jhdateval.Steps === 0) {
            //提交申请  改变步骤状态
            this.jhdateval.Steps = 1; //
          }
          if (this.jhdateval.Steps === 2) {
            //提交请款完成 改变步骤状态
            this.jhdateval.Steps = 4;
          }
          this.zjjhdate(); //
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
    //选择计划金额所属期
    selectjhdate(val) {
      this.options = [];
      this.zj_date.forEach((element) => {
        if (element.jhdate == val) {
          this.jhdateval.Steps = element.Steps;
          this.jhdateval.id = element.id;
          this.jhdateval.jhdate = element.jhdate;
          this.Zjjhlist();
        }
      });
      //
    },
    //编辑计划金额
    edit(row) {
      row.isedit = true;
    },
    //保存计划金额
    save(val) {
      val.isedit = false;
      console.log(val);
      // 增加或修改计划金额
      this.addupdate(val);
    },
    // 增加或修改计划金额
    addupdate(rowinfo) {
      let jhzjinfo = {
        jhmonth: this.jhdateval.jhdate, //日期
        jh_amount: rowinfo.jh_amount, //计划金额
        sp_amount: rowinfo.sp_amount, //审核金额
        category_id: rowinfo.category_id_t, //成本ID
        supplier_id: rowinfo.supplier_id, //供应商ID
        applicant: JSON.parse(window.sessionStorage.getItem("user")).id, //申请人ID
        text_desc: "", //说明描述
        project_id: rowinfo.project_id, //项目ID
        state: 0, //0->表示待审核   1->表示已审核
      };

      axios
        .post("admin/Zjjhlist", jhzjinfo, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then((response) => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });

          console.log("计划金额：", response);
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
    //获取资金计划时间列表
    zjjhdate() {
      this.axios
        .get("admin/zjjhdate", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.data.length > 0) {
            console.log("所属日期", response.data.data);
            this.zj_date = response.data.data;
            // this.Zjjhlist();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //付款明细
    Zjjhlist() {
      this.axios
        .get("admin/Zjjhlist", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            jhmonth: this.jhdateval.jhdate,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.options = response.data.data;
          //初始化合计数
          this.totalamount.contarctamout = 0;
          this.totalamount.requestamout = 0;
          this.totalamount.difference = 0;
          this.totalamount.jh_amount = 0;
          this.totalamount.sp_amount = 0;
          //汇总明细
          this.options.forEach((element) => {
            this.totalamount.contarctamout =
              parseFloat(element.contarctamout) +
              parseFloat(this.totalamount.contarctamout);
            this.totalamount.requestamout =
              parseFloat(element.requestamout) +
              parseFloat(this.totalamount.requestamout);
            this.totalamount.difference =
              parseFloat(element.difference) +
              parseFloat(this.totalamount.difference);
            this.totalamount.jh_amount =
              parseFloat(element.jh_amount) +
              parseFloat(this.totalamount.jh_amount);
            this.totalamount.sp_amount =
              parseFloat(element.sp_amount) +
              parseFloat(this.totalamount.sp_amount);
          });
          console.log("付款明细:", typeof this.options);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //行样式方法
    TableRowStyle(row) {
      let rowBackground = {};
      if (row.row.status === 0) {
        rowBackground.background = "#000080";
        rowBackground.color = "#ffff";
        rowBackground.fontWeight = "bold";
        return rowBackground;
      } else if (row.row.status === 1) {
        rowBackground.background = "#00bbff";
        rowBackground.color = "#ffff";
        rowBackground.fontWeight = "bold";
        return rowBackground;
      } else if (row.row.status === 2) {
        rowBackground.background = "#77ddff";
        rowBackground.fontWeight = "bold";
        return rowBackground;
      } else if (row.row.status === 3) {
        rowBackground.background = "#cceeff";
        return rowBackground;
      } else if (row.row.status === 4) {
        rowBackground.background = "#fff0f5";
        return rowBackground;
      }
      return "";
    },
    //格式化数字
    toThousands(row, column, cellValue) {
      return cellValue.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
  },
  components: {
    "zjjh-fksq": zjjhfksq,
  },
};
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.el-tree-node__content {
  height: 40px;
}
.el-table {
  /* 鼠标经过row显示的背景色 */
  --el-table-row-hover-background-color: transparent;
}
</style>
